<template>

  <div>

    <div v-if="event && project" id="header" class="py-10">

      <div class="w-11/12 lg:w-9/12 mb-12 m-auto flex justify-between gap-18">

        <div>
          <router-link to="/home" class="uppercase text-xs">
            <nag-button button-text="Home" icon-position="left" icon-name="chevron-back"></nag-button>
          </router-link>
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex gap-8 lg:gap-12">
            <div class="flex text-xs">
              <router-link v-if="previousEvent" :to="{ name: 'Event', params: { id: previousEvent.uid }}" class="w-full flex items-center gap-2 hover:underline">
                <nag-icon icon-name="arrow-back"/>
                <div :class="!loaded ? 'text-gray-400' : null">
                  <h6 class="uppercase">Previous Event</h6>
                  <!-- <p class="text-gray-400">{{ previousEvent.data.title[0].text }}</p> -->
                </div>
              </router-link>
            </div>
            <p class="text-xs text-gray-400">{{ currentEventIndex + 1 }} / {{ relatedEvents.length }}</p>
            <div class="flex text-xs">
              <router-link v-if="nextEvent" :to="{ name: 'Event', params: { id: nextEvent.uid }}" class="w-full flex items-center gap-2 hover:underline">
                <div :class="!loaded ? 'text-gray-400' : null">
                  <h6 class="uppercase">Next Event</h6>
                  <!-- <p>{{ nextEvent.data.title[0].text }}</p> -->
                </div>
                <nag-icon icon-name="arrow-forward"/>
              </router-link>
            </div>
          </div>
        </div>
      </div>

      <div class="w-11/12 lg:w-9/12 mb-12 m-auto flex flex-col lg:flex-row gap-8">

        <div class="flex flex-col ml-auto w-full lg:flex-row bg-white border border-black divide-y lg:divide-x lg:divide-y-0 divide-black">

          <div class="w-full lg:w-1/2 divide-y divide-black">

            <div class="flex items-center gap-6 p-4">

              <div class="w-16 h-16 flex items-center bg-yellow-400 rounded-full p-2">
                <img :src="project.data.icon.url" :alt="project.data.icon.alt"/>
              </div>

              <div class="text-md label">
                <span class="text-xs block uppercase tracking-wide text-yellow-400">Project</span>
                <h4 class="font-serif text-xl font-bold italic">
                  {{ event.data.project.id ? project.data.title[0].text : null }}
                </h4>
              </div>

            </div>

            <div class="p-4">
              <span class="text-xs block uppercase tracking-wide text-gray-400">Event</span>
              <h1 class="text-6xl font-bold font-serif italic my-10 leading-none tracking-tight transform -rotate-12 -mt-4">{{event.data.title[0].text}}</h1>
            </div>

          </div>

          <div class="w-full lg:w-1/2 p-6 flex">
              {{ event.data.description[0] ? event.data.description[0].text : null }}
          </div>
        </div>

      </div>

    </div>


    <div class="w-11/12 lg:w-9/12 py-10 m-auto" v-if="event">

      <div class="-my-20 mb-10">
        <div class="grid grid-flow-row grid-cols-3 gap-8">
          <div v-for="(item) in event.data.body" :key="item.id" :class="customWidth(item)" class="col-span-3">
            <ItemText v-if="item.slice_type == 'writing'" :item="item" />
            <ItemImageGallery v-if="item.slice_type == 'image_gallery'" :item="item" />
            <ItemImageGrid v-if="item.slice_type == 'image_grid'" :item="item" />
            <ItemImageSingle v-if="item.slice_type == 'image_single'" :item="item" />
            <ItemDownload v-if="item.slice_type == 'download'" :item="item" />
            <ItemAudio v-if="item.slice_type == 'audio'" :item="item" />
            <ItemVideo v-if="item.slice_type == 'video'" :item="item" />
          </div>
        </div>
      </div>

    </div>

    <Footer />

  </div>


</template>

<script>
import Footer from '@/components/Footer.vue'
import ItemText from '@/components/ItemText.vue'
import ItemImageGallery from '@/components/ItemImageGallery.vue'
import ItemImageGrid from '@/components/ItemImageGrid.vue'
import ItemDownload from '@/components/ItemDownload.vue'
import ItemAudio from '@/components/ItemAudio.vue'
import ItemImageSingle from '@/components/ItemImageSingle.vue'
import ItemVideo from '@/components/ItemVideo.vue'

export default {
  name: 'Event',
  components: {
    Footer,
    ItemText,
    ItemImageGallery,
    ItemImageGrid,
    ItemDownload,
    ItemAudio,
    ItemImageSingle,
    ItemVideo
  },
  async mounted(){
    this.$emit('hideMenu')
    await this.getEvent()
    await this.getProject()
    this.loaded = true
  },
  data(){
    return {
      root_url: process.env.VUE_APP_ROOT_URL,
      event: null,
      project: null,
      loaded: false,
    }
  },
  metaInfo() {
    return {
      title: `${this.event ? this.event.data.title[0].text : ""} - Landing`,
      meta: [
        { name: 'description', content: this.event && this.event.data.description[0] ? this.event.data.description[0].text : ""},
        { property: 'og:title', content: this.event ? this.event.data.title[0].text : "" + ' - Landing'},
        { property: 'og:site_name', content: 'Landing'},
        { property: 'og:description', content: this.event && this.event.data.description[0] ? this.event.data.description[0].text : ""},
        {property: 'og:type', content: 'profile'},
        {property: 'og:url', content: 'https://landing.nanaimoartgallery.ca/event/' + this.$route.params.id},
      ]
    }
  },
  computed: {
    routeParam () {
      return this.$route.params.id
    },
    relatedEvents () {
      return this.$store.state.events.filter(e => {
        // console.log(e.data.project.id)
        return e.data.project.id == this.event.data.project.id
      })
    },
    currentEventIndex () {
      const events = this.relatedEvents
      return events.findIndex(x => x.id === this.event.id)
    },
    nextEvent () {
      //  const events = this.$store.state.events
      const events = this.relatedEvents
      const currentEventIndex = events.findIndex(x => x.id === this.event.id)
      return events[currentEventIndex + 1]
    },
    previousEvent () {
      //  const events = this.$store.state.events
      const events = this.relatedEvents
      const currentEventIndex = events.findIndex(x => x.id === this.event.id)
      // console.log(currentEventIndex - 1)
      // console.log(events[currentEventIndex + 1])
      return events[currentEventIndex - 1]
    }
  },
  watch: {
    async routeParam () {
      this.loaded = false
      this.$emit('hideMenu')
      await this.getEvent()
      await this.getProject()
      this.loaded = true
    }
  },
  methods: {

    async getEvent() {
        await this.$prismic.client.getByUID('events', this.routeParam).then(res => {
          this.event = res
        })
    },

    async getProject() {
        await this.$prismic.client.getByID(this.event.data.project.id).then(res => {
          this.project = res
        })
    },

    customWidth (item) {
      let val = ''
      if (item.slice_type == 'writing') {
        val = 'lg:col-span-2'
      } else if (item.slice_type == 'image_gallery') {
        val = 'lg:col-span-3'
      } else if (item.slice_type == 'download') {
        val = 'lg:col-span-1'
      } else if (item.slice_type == 'audio') {
        val = 'lg:col-span-1'
      } else if (item.slice_type == 'image_single') {
        val = 'lg:col-span-2'
      }
       else if (item.slice_type == 'video') {
        val = 'lg:col-span-2'
      }
      return val
    }

  },
}
</script>
<style scoped>

/* #wrap {
  background-image: url('../assets/contour-header.jpg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
} */

#header:before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: .5;
  z-index: -1;
  overflow: hidden;
  background-image: url('../assets/contour-bg.svg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
}

</style>
