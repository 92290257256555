<template>
  <div class="container">
      <div v-for="item in item.items" :key="item.id" class="item">
      <div> 
        <img :src="item.image.url"/>
      </div>
       
      </div>
  </div>
</template>

<script>

export default {
    name: "ItemImageGrid",
    props: ["item"],

    data() {
      return {
        
      }
    },
    computed: {


    },
    mounted() {
 
    }
}
</script>

<style>

.container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: .5rem;
}
.container>div {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.container>div:before {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.container>div img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%
}

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .container>div:nth-of-type(6n-5) {
    grid-column: span 2;
    grid-row: span 2;
}
}

</style>