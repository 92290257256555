<template>
  <div class="divide-y divide-black border border-black bg-white">
    <div class="h-32 bg-n-lightyellow flex justify-center p-8">
      <vue-plyr class="flex items-center p-4">
        <audio controls crossorigin playsinline>
          <source
              :src="item.primary.audio_url.url"
              type="audio/mp3"
          />
        </audio>
      </vue-plyr>
    </div>
     <div>
      <h3 class="p-4 text-2xl font-serif font-bold italic leading-none">
         {{ item.primary.audio_title[0].text }}
      </h3>
    </div>
   
  </div>
</template>

<script>
export default {
    name: "ItemDownload",
    props: ["item"],
    methods:{
      byteToMegabyte(val){
        return Math.round((val/ 1000000) * 100) / 100
      }
    }
}
</script>

<style>
  :root {
    --plyr-color-main: var(--n-yellow);
    --plyr-audio-controls-background: var(--n-lightyellow);
  }
  .plyr {
    display: flex !important;
    justify-content: center !important;
  }
</style>