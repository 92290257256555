<template>
  <div>
      <img :src="item.primary.image.url" alt="" />
  </div>
</template>

<script>

export default {
    name: 'ItemImageSingle',
    props: ["item"],
}
</script>

<style>

</style>