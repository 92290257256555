<template>
  <div class="embed-container">
    <vimeo-player ref="player" @ready="onReady" :options="options"></vimeo-player>
  </div>
</template>

<script>
export default {
    name: "ItemVideo",
    props: ["item"],
    data () {
      return {
        options: {
          portrait: false,
          responsive: true,
          id: this.item.primary.vimeo_id,
          title: false,
          byline: false,
        }
      }
    },
    methods: {
      onReady() {
        this.playerReady = true
      },
      play () {
        this.$refs.player.play()
      },
      pause () {
        this.$refs.player.pause()
      }
    }
}
</script>

<style>
/* .embed-container { 
  position: relative; 
  padding-bottom: 56.25%; 
  height: 0; overflow: hidden; 
  max-width: 100%; 
} 

.embed-container iframe, 
.embed-container object, 
.embed-container embed { 
  position: absolute; 
  top: 0; left: 0; 
  width: 100%; 
  height: 100%; 
} */
</style>
