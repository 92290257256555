<template>

    <div class="mx-auto py-10 px-24 flex justify-between">
        <p class="text-sm text-gray-300">Nanaimo Landing is an online home for Nanaimo Art Gallery's offsite programs.</p>
        <a class="underline hover:no-underline text-sm">Nanaimo Art Gallery</a>
    </div>  
    
</template>

<script>
export default {
    name: "Footer",
   
}
</script>