<template>
  <div class="border border-black bg-white">

    <div class="grid grid-cols-3 items-center border-b border-black">
      <div class="col-span-2 p-6">
        <strong>{{ item.primary.title1[0].text }}</strong>
      </div>
      <div class="col-span-1 p-6 h-full bg-black text-white text-center">
        {{ item.primary.author[0].text }}
      </div>
    </div>
    <article class="p-6 prose max-w-none prose-headings:font-serif prose-sm lg:prose-base">
      <prismic-rich-text :field="item.primary.content" />
    </article>
    
  </div>
</template>

<script>
export default {
    name: "ItemText",
    props: ["item"]
}
</script>

<style>

</style>