<template>
  <a :href="item.primary.file.url" target="_blank" class="group block border border-black divide-y divide-black bg-white">
    <div class="h-64 bg-yellow-400 flex items-center justify-center bg-no-repeat bg-cover bg-center" :style="`background-image: url(${item.primary.background_image.url})`" >
      <div class="w-24 h-24 rounded-full flex items-center justify-center bg-white group-hover:bg-yellow-400 transition-all">
        <ion-icon name="cloud-download-outline" size="large"></ion-icon>
      </div>
    </div>
    <div>
      <h3 class="p-4 text-2xl font-serif font-bold italic leading-none">
        {{ item.primary.download_title[0].text }}
      </h3>
    </div>
    <div class="text-sm flex justify-between">
      <a :href="item.primary.file.url" target="_blank" class="p-4">Download PDF</a>
      <div class="w-24 p-4 bg-black text-white text-center">{{ byteToMegabyte(item.primary.file.size) }} Mb</div>
    </div>
  </a>
</template>

<script>
export default {
    name: "ItemDownload",
    props: ["item"],
    methods:{
      byteToMegabyte(val){
        return Math.round((val/ 1000000) * 100) / 100
      }
    }
}
</script>

<style>

</style>