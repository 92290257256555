<template>
  <div>
    <LightBox ref="lightbox" :media="images" :show-light-box="false" :show-caption="true"></LightBox>
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide v-for="(item,index) in item.items" :key="item.id" class="z-40">
        <img :src="item.image.url" @click="openGallery(index)" style="height:35vw" class="cursor-pointer"/>
      </swiper-slide>
      <template v-slot:button-prev>
        <div class="swiper-button-prev text-white"></div>
      </template>
      <template v-slot:button-next>
        <div class="swiper-button-next text-white"></div>
      </template>
    </swiper>
  </div>
</template>

<script>
import LightBox from 'vue-it-bigger'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

require('vue-it-bigger/dist/vue-it-bigger.min.css')
// Documentation: https://vuejsexamples.com/a-simple-image-video-lightbox-component-for-vue-js/

export default {
    name: "ItemImageGallery",
    props: ["item"],
    components: {
      Swiper,
      SwiperSlide,
      LightBox
    },
    data() {
      return {
        swiperOptions: {
          observer: true,
          observeParents: true,
          loop: true,
          // centeredSlides: true,
          slidesPerView: 'auto',
          initialSlide : 0,
          spaceBetween: 8,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
          lazyLoading: true,
          preventClicks: false,
          preventClicksPropagation: false,
        },
      }
    },
    methods: {
      openGallery(index) {
        console.log(index)
        this.$refs.lightbox.showImage(index)
      }
    },
    computed: {
      hasNavigation () {
        // console.log(this.item.items)
        return this.item.items.length > 1 ? true : false
      },
      swiper() {
        return this.$refs.mySwiper.$swiper
      },
      images () {
        return this.item.items.map (item => {
          return {
            src: item.image.url,
            thumb: item.image.url,
            caption: item.caption.length ? item.caption[0].text : null
          }
      })
      }
    },
    mounted() {
      // console.log('Current Swiper instance object', this.swiper)
      // this.swiper.slideTo(1, 1000, false)
    }
}
</script>

<style>

.swiper-slide {
  width: auto;
}
</style>